/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0 | 20110126
License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
    }
    .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
    }
    .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    }
    .swiper-container-android .swiper-slide,
    .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
    }
    .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
    }
    .swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    }
    .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    }
    .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    }
    .swiper-slide-invisible-blank {
    visibility: hidden;
    }
    /* Auto Height */
    .swiper-container-autoheight,
    .swiper-container-autoheight .swiper-slide {
    height: auto;
    }
    .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
    }
    /* 3D Effects */
    .swiper-container-3d {
    perspective: 1200px;
    }
    .swiper-container-3d .swiper-wrapper,
    .swiper-container-3d .swiper-slide,
    .swiper-container-3d .swiper-slide-shadow-left,
    .swiper-container-3d .swiper-slide-shadow-right,
    .swiper-container-3d .swiper-slide-shadow-top,
    .swiper-container-3d .swiper-slide-shadow-bottom,
    .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d;
    }
    .swiper-container-3d .swiper-slide-shadow-left,
    .swiper-container-3d .swiper-slide-shadow-right,
    .swiper-container-3d .swiper-slide-shadow-top,
    .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
    }
    .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    /* CSS Mode */
    .swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    }
    .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none;
    }
    .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
    }
    .swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory;
    }
    .swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory;
    }
    :root {
    --swiper-navigation-size: 44px;
    /*
    --swiper-navigation-color: var(--swiper-theme-color);
    */
    }
    .swiper-button-prev,
    .swiper-button-next {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
    }
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    }
    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
    content: 'next';
    }
    .swiper-button-prev.swiper-button-white,
    .swiper-button-next.swiper-button-white {
    --swiper-navigation-color: #ffffff;
    }
    .swiper-button-prev.swiper-button-black,
    .swiper-button-next.swiper-button-black {
    --swiper-navigation-color: #000000;
    }
    .swiper-button-lock {
    display: none;
    }
    :root {
    /*
    --swiper-pagination-color: var(--swiper-theme-color);
    */
    }
    .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    }
    .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
    }
    /* Common Styles */
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
    }
    /* Bullets */
    .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1);
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
    }
    .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
    }
    button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
    }
    .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
    }
    .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    }
    .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
    }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: 200ms transform, 200ms top;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms left;
    }
    .swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
    }
    /* Progress */
    .swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    }
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
    }
    .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top;
    }
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
    }
    .swiper-container-vertical > .swiper-pagination-progressbar,
    .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    }
    .swiper-pagination-white {
    --swiper-pagination-color: #ffffff;
    }
    .swiper-pagination-black {
    --swiper-pagination-color: #000000;
    }
    .swiper-pagination-lock {
    display: none;
    }
    /* Scrollbar */
    .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
    }
    .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
    }
    .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
    }
    .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
    }
    .swiper-scrollbar-cursor-drag {
    cursor: move;
    }
    .swiper-scrollbar-lock {
    display: none;
    }
    .swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    }
    .swiper-zoom-container > img,
    .swiper-zoom-container > svg,
    .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    }
    .swiper-slide-zoomed {
    cursor: move;
    }
    /* Preloader */
    :root {
    /*
    --swiper-preloader-color: var(--swiper-theme-color);
    */
    }
    .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    animation: swiper-preloader-spin 1s infinite linear;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-top-color: transparent;
    }
    .swiper-lazy-preloader-white {
    --swiper-preloader-color: #fff;
    }
    .swiper-lazy-preloader-black {
    --swiper-preloader-color: #000;
    }
    @keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg);
    }
    }
    /* a11y */
    .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
    }
    .swiper-container-fade.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out;
    }
    .swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    }
    .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
    }
    .swiper-container-fade .swiper-slide-active,
    .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
    }
    .swiper-container-cube {
    overflow: visible;
    }
    .swiper-container-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
    }
    .swiper-container-cube .swiper-slide .swiper-slide {
    pointer-events: none;
    }
    .swiper-container-cube.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0;
    }
    .swiper-container-cube .swiper-slide-active,
    .swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
    }
    .swiper-container-cube .swiper-slide-active,
    .swiper-container-cube .swiper-slide-next,
    .swiper-container-cube .swiper-slide-prev,
    .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
    }
    .swiper-container-cube .swiper-slide-shadow-top,
    .swiper-container-cube .swiper-slide-shadow-bottom,
    .swiper-container-cube .swiper-slide-shadow-left,
    .swiper-container-cube .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    }
    .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
    }
    .swiper-container-flip {
    overflow: visible;
    }
    .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    z-index: 1;
    }
    .swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
    }
    .swiper-container-flip .swiper-slide-active,
    .swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
    }
    .swiper-container-flip .swiper-slide-shadow-top,
    .swiper-container-flip .swiper-slide-shadow-bottom,
    .swiper-container-flip .swiper-slide-shadow-left,
    .swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    }

$color_white: #fff;
$color_black: #000;
$color_dark: #A1A1A1;
$color_light: #EAEAE8;
$color_creme: #CECAC4;
$color_typo: $color_black;
$color_bg: $color_white;
$color_navi: $color_black;
$color_link: $color_black;
$error: #D20404;

$font: 'Roboto', sans-serif;
$fontHeading: 'Roboto';

$font_size_base: 2rem;

$wrapper_padding: 3.2rem;
$top_page_padding: 23rem;

html { font-size: calc(10/1366*100vw);
    // @media only screen and (min-width: 1921px) { font-size: 10/1680*100vw; }
    // @media only screen and (max-width: 1119px) and (orientation: portrait) { font-size: 10/1024*100vw; }

    @media only screen and (min-width: 768px) and (max-width: 1365px) { font-size: 10px; }
    // @media only screen and (min-width: 1024px) and (max-width: 1400px) and (orientation: portrait) { font-size: 10/768*100vw; }
    // @media only screen and (max-width: 1023px) { font-size: 10/788*100vw; }
    // @media only screen and (max-width: 1023px) and (orientation: landscape) { font-size: 10/788*100vw; }

    @media only screen and (max-width: 767px) { font-size: 10px; }
    // @media only screen and (max-width: 767px) and (orientation: landscape) { font-size: calc(10/680*100vw); }
    // @media only screen and (max-width: 519px) { font-size: calc(10/375*100vw); }
    // @media only screen and (max-width: 519px) and (orientation: landscape) { font-size: calc(10/519*100vw); }
    // @media only screen and (max-width: 1680px) { font-size: 8px; }
    // @media only screen and (max-width: 1440px) { font-size: 7px; }
    // @media only screen and (max-width: 1280px) { font-size: 6px; }
    // @media only screen and (max-width: 1023px) { font-size: 10px; }

    // &.home {
    //     @media only screen and (max-width: 1199px) { font-size: 10px; }
    // }
}

@mixin phablet {
    @media only screen and (max-width: 767px) { 
        @content;
    }
}

@mixin portrait {
    // @media only screen and (max-width: 1023px) and (orientation: portrait) { 
    @media only screen and (max-width: 1023px) and (orientation: portrait) { 
        @content;
    }
}

@mixin portrait-a {
    @media only screen and (max-width: 1023px) { 
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 1199px) { 
        @content;
    }
}

@mixin tabletLandscape {
    @media only screen and (max-width: 1199px) and (orientation: landscape) { 
        @content;
    }
}

@mixin tabletPro {
    @media only screen and (max-width: 1366px) { 
        @content;
    }
}

@mixin minTablet {
    @media only screen and (min-width: 768px) { 
        @content;
    }
}

@mixin minLaptop {
    @media only screen and (min-width: 1024px) { 
        @content;
    }
}

// @mixin h1 { font-size: 9rem; line-height: (110/90); font-weight: 700; letter-spacing: -0.02em; }

// @mixin h2 {}

// @mixin button {}

// p { font-size: 1.8rem; font-weight: 500; line-height: (30/18); opacity: .8; letter-spacing: -.02em;
//     @media only screen and (max-width: 1023px) { font-size: 1.6rem; line-height: (28/16); }
// }

@mixin h1 { font-size: 8rem; line-height: 1; color: $color_typo; font-weight: 700; text-align: center;
    // @media only screen and (max-width: 767px) { font-size: 2.4rem; line-height: 31/24; }
    // @include portrait {
    //     font-size: 4.5rem;
    //     line-height: 1;
    //     letter-spacing: 0.01em;
    // }

    @include phablet {
        font-size: 3rem;
    }
}

@mixin h2 { font-size: 6.4rem; line-height: 1; color: $color_typo; font-weight: normal;
    // @include portrait {
    //     font-size: 3.8rem;
    //     line-height: 1.1;
    // }
}

@mixin h3 { font-size: 2.4rem; line-height: 2.9rem; color: $color_typo; font-weight: normal; text-align: center;
    // @include portrait {
    //     font-size: 3rem;
    //     line-height: 1.1;
    // }
}

@mixin h4 { font-size: 1.2rem; line-height: 1.4rem; color: $color_typo; text-align: center; font-weight: normal;
    // @include portrait {
    //     font-size: 1.8rem;
    // }
}

@mixin bodyText { font-size: $font_size_base; font-weight: 400; line-height: 1.2; color: $color_typo; font-weight: normal; 
    @include phablet {
        font-size: 1.6rem;
        line-height: calc(21/16);
    }
}

@mixin labelText {
    font-family: $fontHeading;
    font-weight: 600;
    color: $color_navi;
    font-style: italic;
    letter-spacing: 0.02em;
    font-size: 1.3rem;
    line-height: 1.3;
}

h1,
.h1 { @include h1; }

h2,
.h2 { @include h2; }

h3,
.h3 { @include h3; }

h4,
.h4 { @include h4; }

p,
li { @include bodyText; }

button {
    font-family: $font;
    font-size: 1.3rem;
}

a {
    color: $color_link;
}

* { box-sizing: border-box; }

// html { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; height: var(--vh); overflow: hidden; }
html {
    background-color: #F4D03F;
    background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
}
body { width: auto; display: block; font-family: $font; font-size: 1.4rem; line-height: calc(18/14); background-color: $color_bg; color: $color_typo; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -webkit-overflow-scrolling: touch; overflow-x: hidden; padding: 1.5rem; border-radius: 8px; margin: 1rem; overflow: scroll; height: auto;
    // &.is-waiting,
    // &.is-loading {
    //     * { cursor: wait; }
    // }
}

a { text-decoration: none; color: inherit; -webkit-appearance: none;
    .no-touchevents &:hover { color: inherit; }
}

button { background: none; border: 0; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-appearance: none; border-radius: 0; }
a { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
input { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-appearance: none; background: none; }


// .lazy { opacity: 0; transition: opacity 300ms linear; will-change: opacity;
//     &.lazy-loaded { opacity: 1; }
// }

.no-cursor {
    &:not(:fullscreen), *:not(:fullscreen) { cursor: none !important; }
}

*:fullscreen {
    pointer-events: all !important;
    cursor: pointer !important;
}

header {
    margin-bottom: 5rem;
    text-align: center;

    @include phablet {
        margin-bottom: 0;
    }
}

.content {
    display: flex;
    align-items: center;
    margin-bottom: 8rem;

    @include phablet {
        display: block;
    }
}

.content__text {
    width: 50%;
    padding-right: 5rem;
    padding-left: 3rem;

    position: sticky;
    top: 5rem;

    @include portrait {
        position: relative;
        top: unset;
    }

    @include phablet {
        width: 100%;
        padding-right: 0;
        padding-bottom: 3rem;
        padding-left: 0;
    }

    p {
        text-align: center;
        margin-bottom: 1.25em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.content__slider {
    overflow: hidden;
    border-radius: 8px;
    width: 50%;
    position: relative;


    @include phablet {
        width: 100%;
    }
    
    figure {
        position: relative;
        &:before {
            content: '';
            padding-top: 100%;
            display: block;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
}

.contact {

    h2 {
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        @include phablet {
            font-size: 3rem;
            text-align: left;
            margin-left: 0;
        }
    }

    &__row {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 5rem;

        @include phablet {
            align-items: flex-start;
        }

        .map {
            width: 35%;
            overflow: hidden;
            margin-left: auto;
            border-radius: 8px;
            display: flex;

            img {
                width: 100%;
            }
        }


        .contact__text {
            text-align: center;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            address {
                
    
                font-size: 3rem;

                @include phablet {
                    font-size: 1.6rem;
                    text-align: left;
                    white-space: nowrap;
                }
                
            }

            small {
                margin-top: auto;

                @include phablet {
                    margin-top: 5rem;
                    display: block;
                    width: 100%;
                    text-align: center;
                    white-space: nowrap;
                    font-size: 1.2rem;
                }
            }
        }
    }

}

.navi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 4rem);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;

    z-index: 9;


    &__left,
    &__right {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: .8rem;
        background-color: #F4D03F;
        background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &.swiper-button-disabled {
            visibility: hidden;
        }
    }
}

.heading {
    position: relative;
    margin: 0 auto;
    margin-left: 0;
    margin-bottom: 3rem;
    width: auto;
    display: inline-block;
    text-align: left;
    font-weight: 700;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        height: .1em;
        width: 100%;
        background-color: #F4D03F;
        background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);

        border-radius: 4px;
    }
}