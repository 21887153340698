html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.swiper-container {
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: content-box;
  transition-property: transform;
  display: flex;
  position: relative;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-flow: column wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  transition-property: transform;
  position: relative;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container-3d {
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(#00000080, #0000);
}

.swiper-container-css-mode > .swiper-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}

.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-button-prev, .swiper-button-next {
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  text-transform: none !important;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  left: auto;
  right: 10px;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-prev.swiper-button-white, .swiper-button-next.swiper-button-white {
  --swiper-navigation-color: #fff;
}

.swiper-button-prev.swiper-button-black, .swiper-button-next.swiper-button-black {
  --swiper-navigation-color: #000;
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  text-align: center;
  z-index: 10;
  transition: opacity .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  width: 100%;
  bottom: 10px;
  left: 0;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: .2;
  background: #000;
  border-radius: 100%;
  display: inline-block;
}

button.swiper-pagination-bullet {
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-container-vertical > .swiper-pagination-bullets {
  top: 50%;
  right: 10px;
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, top .2s;
  display: inline-block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, left .2s;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, right .2s;
}

.swiper-pagination-progressbar {
  background: #00000040;
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: 100% 0;
}

.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar, .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-white {
  --swiper-pagination-color: #fff;
}

.swiper-pagination-black {
  --swiper-pagination-color: #000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  -ms-touch-action: none;
  background: #0000001a;
  border-radius: 10px;
  position: relative;
}

.swiper-container-horizontal > .swiper-scrollbar {
  z-index: 50;
  height: 5px;
  width: 98%;
  position: absolute;
  bottom: 3px;
  left: 1%;
}

.swiper-container-vertical > .swiper-scrollbar {
  z-index: 50;
  width: 5px;
  height: 98%;
  position: absolute;
  top: 1%;
  right: 3px;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  background: #00000080;
  border-radius: 10px;
  position: relative;
  top: 0;
  left: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-top-color: #0000;
  border-radius: 50%;
  margin-top: -21px;
  margin-left: -21px;
  animation: 1s linear infinite swiper-preloader-spin;
  position: absolute;
  top: 50%;
  left: 50%;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-container .swiper-notification {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  width: 100%;
  height: 100%;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

html {
  font-size: .732064vw;
}

@media only screen and (min-width: 768px) and (max-width: 1365px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 10px;
  }
}

h1, .h1 {
  color: #000;
  text-align: center;
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  color: #000;
  font-size: 6.4rem;
  font-weight: normal;
  line-height: 1;
}

h3, .h3 {
  color: #000;
  text-align: center;
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 2.9rem;
}

h4, .h4 {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.4rem;
}

p, li {
  color: #000;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  p, li {
    font-size: 1.6rem;
    line-height: 1.3125;
  }
}

button {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
}

a {
  color: #000;
}

* {
  box-sizing: border-box;
}

html {
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
}

body {
  width: auto;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  margin: 1rem;
  padding: 1.5rem;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  line-height: 1.28571;
  display: block;
  overflow: scroll;
}

a {
  color: inherit;
  -webkit-appearance: none;
  text-decoration: none;
}

.no-touchevents a:hover {
  color: inherit;
}

button {
  -webkit-tap-highlight-color: #0000;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;
}

a {
  -webkit-tap-highlight-color: #0000;
}

input {
  -webkit-tap-highlight-color: #0000;
  -webkit-appearance: none;
  background: none;
}

.no-cursor:not(:fullscreen), .no-cursor :not(:fullscreen) {
  cursor: none !important;
}

:-webkit-full-screen {
  pointer-events: all !important;
  cursor: pointer !important;
}

:fullscreen {
  pointer-events: all !important;
  cursor: pointer !important;
}

header {
  text-align: center;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 767px) {
  header {
    margin-bottom: 0;
  }
}

.content {
  align-items: center;
  margin-bottom: 8rem;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .content {
    display: block;
  }
}

.content__text {
  width: 50%;
  padding-left: 3rem;
  padding-right: 5rem;
  position: sticky;
  top: 5rem;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  .content__text {
    top: unset;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .content__text {
    width: 100%;
    padding-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.content__text p {
  text-align: center;
  margin-bottom: 1.25em;
}

.content__text p:last-child {
  margin-bottom: 0;
}

.content__slider {
  width: 50%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .content__slider {
    width: 100%;
  }
}

.content__slider figure {
  position: relative;
}

.content__slider figure:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.content__slider figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.contact h2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .contact h2 {
    text-align: left;
    margin-left: 0;
    font-size: 3rem;
  }
}

.contact__row {
  flex-direction: row-reverse;
  margin-top: 5rem;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .contact__row {
    align-items: flex-start;
  }
}

.contact__row .map {
  width: 35%;
  border-radius: 8px;
  margin-left: auto;
  display: flex;
  overflow: hidden;
}

.contact__row .map img {
  width: 100%;
}

.contact__row .contact__text {
  text-align: center;
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.contact__row .contact__text address {
  font-size: 3rem;
}

@media only screen and (max-width: 767px) {
  .contact__row .contact__text address {
    text-align: left;
    white-space: nowrap;
    font-size: 1.6rem;
  }
}

.contact__row .contact__text small {
  margin-top: auto;
}

@media only screen and (max-width: 767px) {
  .contact__row .contact__text small {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    margin-top: 5rem;
    font-size: 1.2rem;
    display: block;
  }
}

.navi {
  width: calc(100% - 4rem);
  z-index: 9;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.navi__left, .navi__right {
  width: 3.5rem;
  height: 3.5rem;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
  border-radius: .8rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navi__left.swiper-button-disabled, .navi__right.swiper-button-disabled {
  visibility: hidden;
}

.heading {
  width: auto;
  text-align: left;
  margin: 0 auto 3rem 0;
  font-weight: 700;
  display: inline-block;
  position: relative;
}

.heading:before {
  content: "";
  height: .1em;
  width: 100%;
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

/*# sourceMappingURL=index.79b993fd.css.map */
